
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.popOverContent {
  position: absolute;
  width: 255px;
  height: auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  background: color.$white-color;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  z-index: 999;
  margin-top: 22px;
  right: -57px;
  border: 1px solid rgba(0, 0, 0, 0.2);

  @include breakpoint('medium') {
    margin-top: 10px;
    width: 255px;
  }
  @include breakpoint('large') {
    z-index: 9;
    margin-top: 26px;
    right: -95px;
  }
  @include breakpoint('xlarge') {
    right: -95px;
  }
  @include breakpoint('xxxlarge') {
    right: -106px;
  }
  &::before {
    width: 10px;
    height: 10px;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    transform: rotate(45deg);
    background: color.$white-color;
    content: '';
    top: -6px;
    position: absolute;
    right: 52px;
    @include breakpoint('medium') {
      right: 57px;
    }
    @include breakpoint('large') {
      right: 115px;
    }
    @include breakpoint('xxxlarge') {
      right: 125px;
    }
  }
  &::after {
    width: 50%;
    height: 32px;
    content: '';
    bottom: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
